/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useNewTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import Button from 'components/uiLibrary/Button';
import ImageAvatar from 'components/uiLibrary/ImageAvatar';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import { COMPANY_TYPE_ID, AGENCY_TYPE_ID, TP, ENTITY_TYPE, PROFILE_TYPES, PROFILE_TYPE_IDS } from 'constants/index';
import { AOS_TYPE_ID, VALIDATION_STATUS } from 'constants/consts';
import { IS_CHECKOUT_ACTIVE, PROFILE_SWITCH } from 'constants/cookieConstants';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import { useDialogs } from 'utils/hooks/useDialogs';
import { setCookie } from 'utils/cookie';

import { getProfileName, getProfilePhotoURL, getProfileSubTitle, getProfilePath } from 'utils/profileDropdown';
import { TRACK_EVENTS, PROFILE_DROPDOWN_EVENT_IDS } from 'utils/tracking';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import classes from './Profile.module.scss';

const Profile = ({
  profile,
  className,
  onClick,
  activeProfilePath,
  updateButtonLabel,
  setProfileDropDownState,
  trackingData,
}) => {
  const track = useNewTracking();
  const { navigate, permissions, entityType } = usePageContext();
  const { setApprovedHiddenProfile } = useDialogs();
  const { t } = useTranslation(['NS_APP_GLOBALS', 'NS_DISPLAY_V4']);
  const isAudience = profile?.profileType?.id === PROFILE_TYPE_IDS.AUDIENCE;

  const getSubscriptionLabel = () => {
    const isAgencyOrCompany =
      profile?.profileType?.id === AGENCY_TYPE_ID || profile?.profileType?.id === COMPANY_TYPE_ID;

    switch (profile?.subscriptionStatus) {
      case PROFILE_TYPES.CHURNED:
      case PROFILE_TYPES.FREEMIUM:
        return t(`${TP}.FN_FREE`);
      case PROFILE_TYPES.PRO:
        if (isAgencyOrCompany) {
          return t(`${TP}.FN_PROFILE_SUBSCRIPTION_TYPE_PRO`);
        }
        return t(`${TP}.FN_PROFILE_SUBSCRIPTION_TYPE_PREMIUM`);
      default:
        if (permissions?.isAdmin && entityType === ENTITY_TYPE.ARTIST) {
          return t(`NS_ENTITY_STUB_PAGE:${TP}.FN_UNCLAIMED`);
        }
        return t(`${TP}.FN_FREE`);
    }
  };

  const profileName = getProfileName(profile);
  const profileSubTitle = getProfileSubTitle(profile);
  const profilePhotoURL = getProfilePhotoURL(profile);
  const profilePath = getProfilePath(profile, navigate.getLinkProps);
  const subscriptionLabel = getSubscriptionLabel();

  const handleUpdateClick = () => {
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_UPDATE_PROFILE,
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );

    if (
      profile?.validationStatus?.slug === VALIDATION_STATUS.APPROVED_HIDDEN &&
      !(profile?.profileType?.id === AGENCY_TYPE_ID || profile?.profileType?.id === AOS_TYPE_ID)
    ) {
      setApprovedHiddenProfile(profile);
      return;
    }

    setProfileDropDownState(false);
    setCookie(PROFILE_SWITCH, true);
    navigate.to({ ...activeProfilePath, shallow: false });

    if (
      [PROFILE_TYPE_IDS.AOS, PROFILE_TYPE_IDS.AGENT].includes(profile?.profileType?.id) &&
      profile?.subscriptionStatus !== PROFILE_TYPES.PRO
    ) {
      setCookie(IS_CHECKOUT_ACTIVE, true);
    }
  };

  return (
    <div className={classnames(classes.profile, className)}>
      <div className={classes.profile__container}>
        <div
          className={classes.profile__avatar}
          onClick={() => {
            onClick({ profileId: profile?.id, path: profilePath });
          }}
        >
          {profilePhotoURL ? (
            <Image
              src={profilePhotoURL}
              alt={profileName}
              transformations={
                entityType === ENTITY_TYPE.ARTIST ? TRANSFORMATIONS.PROFILE_THUMBNAIL : TRANSFORMATIONS.AOS_LOGO
              }
              width={32}
              height={45}
              disableNextImage
              className={classes.image}
            />
          ) : (
            <ImageAvatar
              type="V4"
              shape="rect"
              name={profileName}
              height={45}
              className={classes.imagePlaceholder}
              width={32}
              fontSize={14}
            />
          )}
        </div>
        <div
          aria-hidden="true"
          className={classes.profile__info}
          onClick={() => {
            onClick({ profileId: profile?.id, path: profilePath });
            track.click(
              {
                name: TRACK_EVENTS.CLICK,
                data: {
                  id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_PROFILE_ACTIVE_NAME,
                },
              },
              GOOGLE_OLD_TRACKING_SERVICES,
            );
          }}
        >
          <Typography weight="bold" size={14} className={classes.profile__info_profileName}>
            {profileName}
          </Typography>
          <Typography className={classes.profile__subtitle} weight="medium" size={12}>
            {profileSubTitle}
          </Typography>
          {subscriptionLabel && (
            <div className={classes.badge}>
              <Typography className={classes.badge_text} size="12">
                {subscriptionLabel}
              </Typography>
            </div>
          )}
        </div>
      </div>
      {activeProfilePath.as && (
        <div>
          <Button
            className={classes.buttonStyle}
            leftIcon={<SpriteIcon icon={isAudience ? 'chevron_right' : 'edit'} />}
            variant={isAudience ? 'text' : 'primary'}
            shape="rectangle"
            onClick={handleUpdateClick}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.UPDATE_PROFILE_CTA }}
          >
            {!isAudience && t(updateButtonLabel)}
          </Button>
        </div>
      )}
    </div>
  );
};

Profile.propTypes = {
  profile: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Profile;
