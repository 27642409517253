import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import ImageAvatar from 'components/uiLibrary/ImageAvatar';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';

import { getProfileName, getProfilePhotoURL } from 'utils/profileDropdown';
import { ENTITY_TYPE } from 'constants/index';
import usePageContext from 'utils/hooks/usePageContext';

import classes from './ProfileMenuButton.module.scss';

const ProfileMenuButton = ({ profile, user, onClick, showNotification }) => {
  const profileName = getProfileName(profile) || user?.name;
  const profilePhotoURL = getProfilePhotoURL(profile);
  const { entityType } = usePageContext();

  return (
    <div
      tabIndex={0}
      role="button"
      className={classnames(classes.profileMenuButton, {
        [classes.profileMenuButton__spacing]: !showNotification,
      })}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {profilePhotoURL ? (
        <Image
          src={profilePhotoURL}
          alt={profileName}
          transformations={
            entityType === ENTITY_TYPE.ARTIST ? TRANSFORMATIONS.PROFILE_THUMBNAIL : TRANSFORMATIONS.AOS_LOGO
          }
          width={24}
          height={24}
          disableNextImage
        />
      ) : (
        <ImageAvatar
          name={profileName}
          className={classes.profileMenuButton__avatar}
          width={24}
          height={24}
          fontSize={12}
        />
      )}

      <SpriteIcon icon="arrow_drop_down" className={classes.profileMenuButton__arrowIcon} />
    </div>
  );
};

ProfileMenuButton.propTypes = {
  profile: PropTypes.object,
  onClick: PropTypes.func,
  user: PropTypes.object,
};

export default ProfileMenuButton;
