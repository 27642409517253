import React from 'react';
import PropTypes from 'prop-types';
import { i18n, useTranslation } from 'src/i18n';

import useNewTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import Button from 'components/uiLibrary/Button';

import { TP } from 'constants/index';
import { IS_ACTION_CENTER } from 'constants/cookieConstants';

import { bugsnagNotify } from 'utils/bugsnagClientWrapper';
import { removeCookie } from 'utils/cookie';
import { TRACK_EVENTS, PROFILE_DROPDOWN_EVENT_IDS, resetUserOnLogout } from 'utils/tracking';

import classes from './Logout.module.scss';

const Logout = ({ user, trackingData = {} }) => {
  const { t } = useTranslation();
  const track = useNewTracking();
  const handleLogout = () => {
    try {
      resetUserOnLogout(user);
      removeCookie(IS_ACTION_CENTER);
    } catch (e) {
      bugsnagNotify(e);
    } finally {
      window.location.href = `/auth/logout?lang=${i18n.language}`;
      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          data: {
            id: PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_LOGOUT,
          },
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
    }
  };

  return (
    <div className={classes.logoutSection}>
      <div className={classes.logoutSection__email}>{user?.email}</div>
      <Button
        className={classes.logoutSection__btn}
        shape="rectangle"
        variant="secondary"
        onClick={handleLogout}
        trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.LOGOUT_CTA }}
      >
        {t(`${TP}.m_LOGOUT`)}
      </Button>
    </div>
  );
};

Logout.propTypes = {
  user: PropTypes.object,
};

export default Logout;
