import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import classes from './Badge.module.scss';
import Typography from '../Typography';

const Badge = ({ variant, content, size = '12', className }) => {
  let borderType = '';
  if (content > 100) {
    borderType = 'pill';
  } else if (content >= 0 && content <= 99) {
    borderType = 'circle';
  } else {
    borderType = 'dot';
  }

  return (
    <Typography
      color="white"
      className={classnames(classes.badge, classes[borderType], classes[variant], className)}
      size={size}
      weight="bold"
    >
      {content > 99 ? '99+' : content}
    </Typography>
  );
};

Badge.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  content: PropTypes.oneOfType([PropTypes.number]),
};

export default Badge;
