import React from 'react';
import PropTypes from 'prop-types';
import MUIMenu from '@mui/material/Menu';

const Menu = ({
  anchorEl,
  isOpen,
  onClose,
  children,
  className,
  disableArrow = false,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
}) => (
  <MUIMenu
    id="menu"
    anchorEl={anchorEl}
    open={isOpen}
    onClose={onClose}
    classes={{ paper: className }}
    PaperProps={
      !disableArrow && {
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          // '&:before': {
          //   content: '""',
          //   display: 'block',
          //   position: 'absolute',
          //   top: 0,
          //   right: 14,
          //   width: 10,
          //   height: 10,
          //   bgcolor: 'background.paper',
          //   transform: 'translateY(-50%) rotate(45deg)',
          //   zIndex: 0,
          // },
        },
      }
    }
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
  >
    {children}
  </MUIMenu>
);

Menu.propTypes = {
  anchorEl: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
};

export default Menu;
